
"use strict";

  const resizerAnimationStopper = {
    init: function() {
      let resizeTimer;
      window.addEventListener("resize", () => {
        // defaults.elements.$body.addClass("resize-animation-stopper");
        document.body.classList.add("resize-animation-stopper");
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          // defaults.elements.$body.removeClass("resize-animation-stopper");
            document.body.classList.remove("resize-animation-stopper");
        }, 400);
      });
    }
  };

  export default resizerAnimationStopper