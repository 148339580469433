import baguetteBox from 'baguettebox.js';
import 'baguettebox.js/dist/baguetteBox.min.css';

const thisLightbox = {
    elements: '.js-lightbox',
    instances: {},  // Changed to object to store galleries

    init: function() {
        // Initialize each lightbox element
        baguetteBox.run(this.elements);
    }
  };

  export default thisLightbox;